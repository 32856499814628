import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import './assets/styles/index.scss';
import 'antd/dist/reset.css';

import App from './App';
import GlobalContext from './basic/GlobalContext';

const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});
const root = createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<GlobalContext>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</BrowserRouter>
		</GlobalContext>
	</React.StrictMode>,
);
