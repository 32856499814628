import {useContext, createContext, useReducer} from 'react';

const initial_values = {
	isProd: process.env.NODE_ENV === 'production',
	isAuthorized: false,
	token: 'token',
};

const reducer = (state, action)=>{
	const {type, payload} = action;

	switch(type){
		case 'SET_TOKEN':
		case 'SET':
			return {...state, ...payload};
		default:
			console.log('[GlobalContext] reducer: no set new state');
			return state;
	}
};

//

const ValuesContext = createContext();

const GlobalContext = ({children})=>{
	const [state, dispatch] = useReducer(reducer, initial_values);

	return <ValuesContext.Provider value={{state, dispatch}}>{children}</ValuesContext.Provider>;
};

//

export const useGlobalContext = ()=>useContext(ValuesContext);
export default GlobalContext;
