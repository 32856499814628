import {Routes, Route, Navigate} from 'react-router-dom';

import HomePage from '../pages/Home/HomePage';
import Eula from '../pages/Eula/Eula';
import Privacy from '../pages/Privacy/Privacy';

const Routers = ()=>{
	return (
		<Routes>
			<Route path='/' element={<HomePage />} />
			<Route path='eula' element={<Eula />} />
			<Route path='privacy' element={<Privacy />} />

			<Route path='*' element={<Navigate to='/' />} />
		</Routes>
	);
};

export default Routers;
