import {useNavigate} from 'react-router-dom';
import {Avatar, Card, Space} from 'antd';

import cl from './HomePage.module.scss';

import favIcon from '../../assets/favicon.png';
import chromeWebStore from '../../assets/chromeWebStore.png';

//
//
//

const HomePage = ()=>{
	const navigate = useNavigate();

	//
	//
	//

	return (
		<div className={cl.wrapper}>
			<Space direction='vertical'>
				<div className={cl.block}>
					<h1 className={cl.headerTitle}>SearchResults.store</h1>

					<Avatar src={favIcon}></Avatar>
				</div>

				<div className={cl.block}>
					<div className={cl.description}>
						<span>Change your browser default search to this great alternative and search the web easily and efficiently.</span>
						<span>Over 50,000 users have already made their choice and do not regret!</span>
						<span>We are devoted to the mission of open, efficient and fast web and will develop this extension with the best positive practices existing!</span>
					</div>

					<div className={cl.right}>
						<img onClick={()=>window.open('https://translate.google.com/')} src={chromeWebStore} alt='chrome web store' />
						<span>By clicking "Add to chrome" I accept and agree to installing this search engine and change default search to provided one by the service</span>
					</div>
				</div>

				<Card>
					<Card.Grid onClick={()=>window.open('mailto:contact@searchresults.store?subject=UltraSafeSearch')}>
						<Card.Meta title='Contact' description='Mail us contact@searchresults.store' />
					</Card.Grid>
					<Card.Grid onClick={()=>navigate('/eula')}>
						<Card.Meta title='Eula' description='EULA Policy' />
					</Card.Grid>
					<Card.Grid onClick={()=>navigate('/privacy')}>
						<Card.Meta title='Privacy' description='Privacy Policy' />
					</Card.Grid>
				</Card>
			</Space>
		</div>
	);
};

//
//
//

export default HomePage;
