import Routers from './basic/Routers';

import cl from './assets/styles/App.module.scss';
import {Layout} from 'antd';

const App = ()=>{
	return (
		<Layout className={cl.wrapper}>
			<Layout.Content className={cl.content}>
				<div className={cl.bodya}>
					<Routers />
				</div>
			</Layout.Content>
		</Layout>
	);
};

export default App;
