// import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import cl from './Eula.module.scss';

//
//
//

const Eula = ()=>{
	// const [aEula, setEula] = useState(null);

	const navigate = useNavigate();

	const transit = ()=>navigate('/search?q=vazup');

	return (
		<>
			<h2>
				<span className='s1'>
					<b>END USER LICENSE AGREEMENT</b>
				</span>
			</h2>
			<p style={{display: 'flex', flexDirection: 'column'}}>
				<span className='s2'>
					<span className={cl.alink} onClick={transit}>searchresults.store</span>
				</span>
				<span className='s1'>
					Extension Copyright © 2023<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<p className='p3'>
				<span className='s1'></span>
			</p>
			<p className='p4'>
				<span className='s1'>
					<b>IMPORTANT: PLEASE READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.</b>
					<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<h3>
				<span className='s1'>
					<b>1. LICENSE</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						By receiving, opening the file package, and/or using searchresults.store Extension("Software") containing this software, you agree that this End User
						User License Agreement(EULA) is a legally binding and valid contract and agree to be bound by it. You agree to abide by the intellectual property laws
						and all of the terms and conditions of this Agreement.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						Unless you have a different license agreement signed by searchresults.store your use of searchresults.store Extension indicates your acceptance of
						this license agreement and warranty.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						Subject to the terms of this Agreement, searchresults.store grants to you a limited, non-exclusive, non-transferable license, without right to
						sub-license, to use searchresults.store Extension in accordance with this Agreement and any other written agreement with searchresults.store.
						searchresults.store does not transfer the title of searchresults.store Extension to you; the license granted to you is not a sale. This agreement is a
						binding legal agreement between searchresults.store and the purchasers or users of searchresults.store Extension.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						If you do not agree to be bound by this agreement, remove searchresults.store Extension from your computer now and, if applicable, promptly return to
						searchresults.store by mail any copies of searchresults.store Extension and related documentation and packaging in your possession.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>2. DISTRIBUTION</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						searchresults.store Extension and the license herein granted shall not be copied, shared, distributed, re-sold, offered for re-sale, transferred or
						sub-licensed in whole or in part except that you may make one copy for archive purposes only. For information about redistribution of
						searchresults.store Extension contact searchresults.store.<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>3. USER AGREEMENT</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Use:</b> Your license to use searchresults.store Extension is limited to the number of licenses purchased by you. You shall not allow others to
						use, copy or evaluate copies of searchresults.store Extension.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Use Restrictions:</b> You shall use searchresults.store Extension in compliance with all applicable laws and not for any unlawful purpose. Without
						limiting the foregoing, use, display or distribution of searchresults.store Extension together with material that is pornographic, racist, vulgar,
						obscene, defamatory, libelous, abusive, promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage, race, sexual
						orientation or age is strictly prohibited. Each licensed copy of searchresults.store Extension may be used on one single computer location by one
						user. Use of searchresults.store Extension means that you have loaded, installed, or run searchresults.store Extension on a computer or similar
						device. If you install searchresults.store Extension onto a multi-user platform, server or network, each and every individual user of
						searchresults.store Extension must be licensed separately. You may make one copy of searchresults.store Extension for backup purposes, providing you
						only have one copy installed on one computer being used by one person. Other users may not use your copy of searchresults.store Extension . The
						assignment, sublicense, networking, sale, or distribution of copies of searchresults.store Extension are strictly forbidden without the prior written
						consent of searchresults.store. It is a violation of this agreement to assign, sell, share, loan, rent, lease, borrow, network or transfer the use of
						searchresults.store Extension. If any person other than yourself uses searchresults.store Extension registered in your name, regardless of whether it
						is at the same time or different times, then this agreement is being violated and you are responsible for that violation!
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Copyright Restriction:</b> This Software contains copyrighted material, trade secrets and other proprietary material. You shall not, and shall not
						attempt to, modify, reverse engineer, disassemble or decompile searchresults.store Extension. Nor can you create any derivative works or other works
						that are based upon or derived from searchresults.store Extension in whole or in part. searchresults.store's name, logo and graphics file that
						represents searchresults.store Extension shall not be used in any way to promote products developed with searchresults.store Extension
						.searchresults.store retains sole and exclusive ownership of all right, title and interest in and to searchresults.store Extension and all
						Intellectual Property rights relating thereto. Copyright law and international copyright treaty provisions protect all parts of searchresults.store
						Extension, products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended
						within the bounds of the single user program. All rights not expressly granted hereunder are reserved for searchresults.store.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Limitation of Responsibility:</b> You will indemnify, hold harmless, and defend searchresults.store , its employees, agents and distributors
						against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of searchresults.store 's Software. In
						no event (including, without limitation, in the event of negligence) will searchresults.store , its employees, agents or distributors be liable for
						any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages for loss of profits, loss of
						use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to this Agreement,
						searchresults.store Extension or the use or inability to use searchresults.store Extension or the furnishing, performance or use of any other matters
						hereunder whether based upon contract, tort or any other theory including negligence. searchresults.store's entire liability, without exception, is
						limited to the customers' reimbursement of the purchase price of the Software (maximum being the lesser of the amount paid by you and the suggested
						retail price as listed by searchresults.store ) in exchange for the return of the product, all copies, registration papers and manuals, and all
						materials that constitute a transfer of license from the customer back to searchresults.store.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Warranties:</b> Except as expressly stated in writing, searchresults.store makes no representation or warranties in respect of this Software and
						expressly excludes all other warranties, expressed or implied, oral or written, including, without limitation, any implied warranties of merchantable
						quality or fitness for a particular purpose.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Governing Law</b> This Agreement shall be governed by the law of the Hong Kong, (China) applicable therein. You hereby irrevocably attorn and
						submit to the non-exclusive jurisdiction of the courts of Hong Kong, (China) therefrom. If any provision shall be considered unlawful, void or
						otherwise unenforceable, then that provision shall be deemed severable from this License and not affect the validity and enforceability of any other
						provisions.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Termination:</b> Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this
						license. Upon termination of this license granted herein for any reason, you agree to immediately cease use of searchresults.store Extension and
						destroy all copies of searchresults.store Extension supplied under this Agreement. The financial obligations incurred by you shall survive the
						expiration or termination of this license.<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>DISCLAIMER OF WARRANTY:</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD "AS IS" AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER
						EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY searchresults.store Extension AS WELL.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>CONSENT OF USE OF DATA</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						You agree that searchresults.store may collect and use information gathered in any manner as part of the product support services provided to you, if
						any, related to searchresults.store Extension.
						<span className={cl.alink} onClick={transit}>
							<span className='s2'> searchresults.store </span>
						</span>
						may also use this information to provide notices to you which may be of use or interest to you.<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>CONTACT US</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>You can find our contact information on main page</span>
				</li>
			</ul>
		</>
	);
};

//
//
//

export default Eula;
