// import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import cl from './Privacy.module.scss';

//
//
//

const Privacy = ()=>{
	// const [aPrivacy, setPrivacy] = useState(null);

	const navigate = useNavigate();

	const transit = ()=>navigate('/search?q=vazup');

	return (
		<>
			<h2>
				<span className='s1'>
					<b>PRIVACY POLICY</b>
				</span>
			</h2>
			<p style={{display: 'flex', flexDirection: 'column'}}>
				<span className='s2'>
					<span className={cl.alink} onClick={transit}>
						searchresults.store
					</span>
				</span>
				<span className='s1'>
					Extension Copyright © 2023<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<p className='p3'>
				<span className='s1'>
					<b>IMPORTANT: PLEASE READ THIS POLICY CAREFULLY BEFORE USING THIS SOFTWARE.</b>
					<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<p className='p3'>
				<span className='s1'>
					This Privacy Policy (the "Policy") describes the privacy practices with respect to the service ("Services"), as such term is defined in the end user
					license agreement available here:
					<span className={cl.alink} onClick={()=>navigate('/eula')}>
						<span className='s2'> EULA</span>
					</span>
					. Please also review the EULA as your use of the Services is subject to the terms of this Policy and the EULA.
					<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<p className='p3'>
				<span className='s1'>By downloading and/or installing and/or using the Services and/or by accepting this Policy and the EULA.</span>
			</p>
			<p className='p3'>
				<span className='s1'>
					By downloading and installing the Services and/or by accepting this Policy and the EULA, you consent to our access, collection, use and/or disclosure of
					your information as described in the Third Party's Policies and in this Policy.<span className='Apple-converted-space'> </span>
				</span>
			</p>
			<h3>
				<span className='s1'>
					<b>Information We Collect and Use</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Basic Information.</b> We may collect non-personal and technical information from you. This information may include your type of browser(s), your
						IP address and your computer operating system details. We collect and use this information to enable the Services to work as it is intended, maintain,
						measure the performance of and improve the Services.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Personal Information You Provide Us.</b> When you contact us,we keep a record of your communication with us (including your email address)
						("Personal Information") to provide you with support services, and, if applicable, investigate any issues that you raise. We do not use this
						information for advertising purposes.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Additional features.</b> We are always seeking to improve the Services. Therefore,we may introduce new features, some of which may result in the
						collection of additional information from you. If we start collecting additional types of personal information and materially change how we handle
						your information, we will notify you in accordance with Section 'E' (Changes to This Policy).<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Cookies.</b> As part of the Software we may use cookies and similar technologies to collect information such as your usage data and to improve the
						Software. A cookie is a small text file saved on your computer to help store preferences and other information that is used on webpages that you
						visit. We may use cookies to better understand how you interact with the Software and to customize and improve the Software. Most Internet browsers
						automatically accept cookies. You can change the settings of your browser at any time to delete/disable cookies. However, some features of the
						Software may not function properly if you disable cookies.<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>Information Sharing and Disclosure</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>General.</b>We do not rent, sell, or share your Personal Information except in the limited circumstances described in this Policy or in the event
						that we have a good faith belief that disclosure is permitted by law or is reasonably necessary to:(i) comply with the law, a legal requirement or
						process,including without limitation, subpoenas, court orders, or other compulsory disclosures, or to establish, maintain or exercise our legal
						rights;(ii) enforce this Policy or the EULA;(iii) investigate,prevent,or take action regarding suspected or actual illegal activities or violation of
						third party rights;or (iv) protect our rights. In addition,in the event that we undergo a bankruptcy, merger, acquisition, reorganization or sale of
						assets, your information may be one of the assets sold or transferred.<span className='Apple-converted-space'> </span>
					</span>
				</li>
				<li className='li1'>
					<span className='s3'>
						<b></b>
					</span>
					<span className='s1'>
						<b>Data Transfer.</b> We may transfer your information and process it on servers outside your country of residence.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>Security</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						We have implemented security safeguards designed to protect your personal information in accordance with industry standards. We limit access to your
						information on our servers, and it is password-protected/encrypted. We also regularly monitor our servers for possible vulnerabilities and attacks.
						Nevertheless, as the Internet is not a 100% secure environment, we cannot guarantee the security of any information that you transmit to us. There is
						no guarantee that information on our servers may not be accessed, disclosed, altered, or destroyed by breach of our safeguards.
						<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>Changes to This Policy</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>
						We may change this Policy from time to time. If we make changes, that are significant in our sole discretion, in the way we treat your personal
						information, or to the Policy, we will provide you with notice as part of the Services or by any other means. Your continued use of the Services,
						following any change to this Policy, constitutes your complete and irrevocable acceptance of the updated Policy. If any changes to the Policy are not
						acceptable to you, you may uninstall the Services.<span className='Apple-converted-space'> </span>
					</span>
				</li>
			</ul>
			<h3>
				<span className='s1'>
					<b>Contact us</b>
				</span>
			</h3>
			<ul className='ul1'>
				<li className='li1'>
					<span className='s3'></span>
					<span className='s1'>You can find our contact information on main page</span>
				</li>
			</ul>
		</>
	);
};

//
//
//

export default Privacy;
